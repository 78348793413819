import { InsureIQCoverageStatus } from '@harvestiq/constants';
import { zIsoDate } from '@harvestiq/zod';
import { z } from 'zod';
import { dbUserSchema } from '../users/db-user';
import { mediaMetaDataSchema } from '../media/media-meta-data';
import { dbCompanySchema } from '../companies/db-companies';
import { dbUserProfileSchema } from '../user-profiles/db-user-profile';
import { entitySchema } from '../entities/entity';

export const dbSharedEndorsementSchema = z.object({
  id: z.string().uuid(),
  agentSignedAt: z.coerce.date().nullable(),
  createdBy: z.string().uuid().nullable(),
  customerSignedAt: z.coerce.date().nullable(),
  externalStatus: z.string().nullable(),
  isExternallyManaged: z.boolean(),
  policyId: z.string().uuid().nullable(),
  salesEffectiveDate: zIsoDate(),
  status: z.nativeEnum(InsureIQCoverageStatus),
  submittedToAipAt: z.coerce.date().nullable(),
  entityId: z.string().uuid().nullable(),
  watchedAt: z.coerce.date().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
});

const endorsementUserProfileSchema = dbUserSchema
  .omit({ password: true })
  .extend({
    profile: dbUserProfileSchema
      .pick({
        id: true,
        userId: true,
        phones: true,
      })
      .nullable(),
  })
  .nullable();

export const sharedEndorsementSchema = dbSharedEndorsementSchema.extend({
  coveragePdf: mediaMetaDataSchema.nullable(),
  entity: entitySchema.nullable(),
  userOwner: endorsementUserProfileSchema.optional(),
  companyOwner: dbCompanySchema.omit({ ein: true }).optional().nullable(),
  agent: endorsementUserProfileSchema,
});

export type SharedEndorsement = z.infer<typeof sharedEndorsementSchema>;
