import { z } from 'zod';
import {
  ColumnType,
  GeneratedAlways,
  Insertable,
  Selectable,
  Updateable,
} from 'kysely';

export type AipSettingsTable = {
  id: GeneratedAlways<string>;
  aip: ColumnType<string>;
  agencyCode: ColumnType<string>;
  addressLine1: ColumnType<string>;
  addressLine2: ColumnType<string | null>;
  city: ColumnType<string>;
  state: ColumnType<string>;
  zip: ColumnType<string>;
  email: ColumnType<string>;
  phone: ColumnType<string>;
  fax: ColumnType<string | null>;
  isActive: ColumnType<boolean>;
  isDefault: ColumnType<boolean>;
  createdAt: ColumnType<Date>;
  updatedAt: ColumnType<Date>;
};

export type DbAipSettings = Selectable<AipSettingsTable>;
export type DbAipSettingsInsert = Insertable<AipSettingsTable>;
export type DbAipSettingsUpdate = Updateable<AipSettingsTable>;

export const dbAipSettingsSchema = z.object({
  aip: z.string(),
  agencyCode: z.string(),
  addressLine1: z.string(),
  addressLine2: z.string().nullable(),
  city: z.string(),
  state: z.string(),
  zip: z.string(),
  email: z.string().email(),
  phone: z.string(),
  fax: z.string().nullable(),
  isActive: z.boolean(),
  isDefault: z.boolean(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type dbAipSettings = z.infer<typeof dbAipSettingsSchema>;
