import { z } from 'zod';
import { safeUserSchema } from '../users/user';
import { dbEntitySchema } from './db-entity';

export const entitySchema = dbEntitySchema.extend({
  owner: safeUserSchema.nullable(),
  otherEntities: z.array(dbEntitySchema),
});

export type Entity = z.infer<typeof entitySchema>;
