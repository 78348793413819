import { z } from 'zod';
import {
  dbLgmEndorsementSchema,
  dbLgmEndorsementUpdateSchema,
} from './db-lgm-endorsement';
import {
  dbLrpEndorsementSchema,
  dbLrpEndorsementUpdateSchema,
} from './db-lrp-endorsement';
import {
  lgmCoverageTotalValuesSchema,
  lrpCoverageTotalValuesSchema,
} from './coverage-total-values';
import { zCoercedBoolean } from '@harvestiq/zod';

export const updateLrpCoverageRequestSchema = dbLrpEndorsementUpdateSchema
  .pick({
    type: true,
    commodity: true,
    commodityType: true,
    details: true,
    agentSignedAt: true,
    createdBy: true,
    customerSignedAt: true,
    externalStatus: true,
    isExternallyManaged: true,
    policyId: true,
    salesEffectiveDate: true,
    status: true,
    submittedToAipAt: true,
    watchedAt: true,
    deletedAt: true,
    liveCattleTargetCwtPerHead: true,
    feederCattleTargetCwtPerHead: true,
    cornTargetBuPerHead: true,
  })
  // API Endpoint specific fields
  .extend({
    sendAgentNotification: z.boolean().optional(),
    sendCustomerNotification: z.boolean().optional(),
    enableEntities: zCoercedBoolean().optional(),
  });
export type UpdateLrpCoverageRequest = z.infer<
  typeof updateLrpCoverageRequestSchema
>;

export const updateLgmCoverageRequestSchema = dbLgmEndorsementUpdateSchema
  .pick({
    type: true,
    commodity: true,
    commodityType: true,
    details: true,
    agentSignedAt: true,
    createdBy: true,
    customerSignedAt: true,
    externalStatus: true,
    isExternallyManaged: true,
    policyId: true,
    salesEffectiveDate: true,
    status: true,
    submittedToAipAt: true,
    watchedAt: true,
    deletedAt: true,
    liveCattleTargetCwtPerHead: true,
    feederCattleTargetCwtPerHead: true,
    cornTargetBuPerHead: true,
  })
  // API Endpoint specific fields
  .extend({
    sendAgentNotification: z.boolean().optional(),
    sendCustomerNotification: z.boolean().optional(),
    enableEntities: zCoercedBoolean().optional(),
  });
export type UpdateLgmCoverageRequest = z.infer<
  typeof updateLgmCoverageRequestSchema
>;

export const updateCoverageRequestSchema = z.union([
  updateLrpCoverageRequestSchema,
  updateLgmCoverageRequestSchema,
]);
export type UpdateCoverageRequest = z.infer<typeof updateCoverageRequestSchema>;

// Responses
export const updateLrpCoverageResponseSchema = z.object({
  data: z
    .object({
      coverage: dbLrpEndorsementSchema.extend({
        // Virtual fields from Sequelize model
        // TODO: Remove this when we have a better way to handle virtual fields
        totalValues: lrpCoverageTotalValuesSchema,
      }),
      filename: z.string().nullable(),
    })
    .array(),
});
export type UpdateLrpCoverageResponse = z.infer<
  typeof updateLrpCoverageResponseSchema
>;

export const updateLgmCoverageResponseSchema = z.object({
  data: z
    .object({
      coverage: dbLgmEndorsementSchema.extend({
        // Virtual fields from Sequelize model
        // TODO: Remove this when we have a better way to handle virtual fields
        totalValues: lgmCoverageTotalValuesSchema,
      }),
      filename: z.string().nullable(),
    })
    .array(),
});
export type UpdateLgmCoverageResponse = z.infer<
  typeof updateLgmCoverageResponseSchema
>;
