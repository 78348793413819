import { z } from 'zod';
import { policyDataSchema } from '../policies/policy-data';
import { InsureIQPolicyTypes } from '@harvestiq/constants';
import { dbAipSettingsSchema } from '../aip-settings/db-aip-settings';
import { safeUserSchema } from '../users/user';
import { lrpEndorsementSchema } from '../coverages/lrp-endorsement';
import { lgmEndorsementSchema } from '../coverages/lgm-endorsement';

export const policyApplicationPdfSchema = z.object({
  type: z.nativeEnum(InsureIQPolicyTypes),
  policy: policyDataSchema,
  agent: safeUserSchema.optional().nullable(),
  agencyInfo: dbAipSettingsSchema.optional(),
  lgmEndorsement: lgmEndorsementSchema.optional(),
  lrpEndorsement: lrpEndorsementSchema.optional(),
  enableEntities: z.boolean(),
});
export type PolicyApplicationPdf = z.infer<typeof policyApplicationPdfSchema>;
