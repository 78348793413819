import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { z } from 'zod';
import {
  getSGPortalClientId,
  getSGPortalClientSecret,
  getSGPortalHost,
} from '@harvestiq/config';
import {
  LgmPricesRequest,
  LgmPricesResponse,
  lgmPricesRequestSchema,
  lgmPricesResponseSchema,
  LgmQuoteRequest,
  GetLgmQuoteResponse,
  lgmQuoteRequestSchema,
  getLgmQuoteResponseSchema,
  LrpOptionsResponse,
  lrpOptionsResponseSchema,
  LrpQuoteRequest,
  LrpQuotesResponse,
  lrpQuoteRequestSchema,
  lrpQuotesResponseSchema,
  LrpQuoteByTypeCodeAndSedRequest,
  LrpQuoteByTypeCodeAndSedResponse,
  lrpQuoteByTypeCodeAndSedRequestSchema,
  lrpQuoteByTypeCodeAndSedResponseSchema,
  SalesEffectiveDatesResponse,
  salesEffectiveDatesResponseSchema,
  CreateUserRequest,
  CreateUserResponse,
  createUserRequestSchema,
  createUserResponseSchema,
  updateUserRequestSchema,
  updateUserResponseSchema,
  UpdateUserRequest,
  UpdateUserResponse,
  CreatePolicyRequest,
  CreatePolicyResponse,
  createPolicyRequestSchema,
  createPolicyResponseSchema,
  CoverageReminderApiResponse,
  coverageReminderApiResponseSchema,
  CreateLrpCoverageRequest,
  createLrpCoverageRequestSchema,
  CreateLrpCoverageResponse,
  createLrpCoverageResponseSchema,
  CreateLgmCoverageRequest,
  createLgmCoverageRequestSchema,
  CreateLgmCoverageResponse,
  createLgmCoverageResponseSchema,
  AllOrgAppSettingsResponse,
  allOrgAppSettingsResponseSchema,
  coverageReminderApiRequestSchema,
  CoverageReminderApiRequest,
  UpdateUserProfileRequest,
  UpdateUserProfileResponse,
  updateUserProfileResponseSchema,
  updateUserProfileRequestSchema,
  GetUserResponse,
  getUserResponseSchema,
  GetUserRequest,
  getUserRequestSchema,
  DbPolicyUpdate,
  dbPolicyUpdateSchema,
  UpdatePolicyResponse,
  updatePolicyResponseSchema,
  CoverageIndemnityApiRequest,
  CoverageIndemnityApiResponse,
  coverageIndemnityApiResponseSchema,
  coverageIndemnityApiRequestSchema,
  LgmIndemnityRequest,
  lgmIndemnityRequestSchema,
  LgmIndemnityResponse,
  lgmIndemnityResponseSchema,
  UpdateUserAgentRequest,
  UpdateUserAgentResponse,
  updateUserAgentResponseSchema,
  updateUserAgentRequestSchema,
  lrpEndingValuesRequestSchema,
  LrpEndingValuesRequest,
  LrpEndingValuesResponse,
  lrpEndingValuesResponseSchema,
  LrpIndemnityRequest,
  LrpIndemnityResponse,
  lrpIndemnityResponseSchema,
  lrpIndemnityRequestSchema,
  UpdateLrpCoverageRequest,
  updateLrpCoverageRequestSchema,
  UpdateLrpCoverageResponse,
  updateLrpCoverageResponseSchema,
  UpdateLgmCoverageRequest,
  UpdateLgmCoverageResponse,
  updateLgmCoverageResponseSchema,
  updateLgmCoverageRequestSchema,
  GetCoverageResponse,
  getCoverageResponseSchema,
  GetOrgFmhKeysResponse,
  getOrgFmhKeysResponseSchema,
  GetUserByIdResponse,
  getUserByIdResponseSchema,
  InsureIQInsuranceQuoteResponse,
  insureIQInsuranceQuoteResponseSchema,
  insureIQQuoteCampaignRequestSchema,
  InsureIQQuoteCampaignRequest,
  DbPolicy,
  getPolicyResponseSchema,
  SearchEntitiesRequest,
  SearchEntitiesResponse,
  searchEntitiesResponseSchema,
  searchEntitiesRequestSchema,
  GetCoveragesResponse,
  getCoveragesResponseSchema,
  getCoveragesRequestSchema,
  GetCoveragesRequest,
  InsureIQEmailCoveragesReportRequest,
  InsureIQEmailCoveragesReportResponse,
  insureIQEmailCoveragesReportResponseSchema,
  insureIQEmailCoveragesReportRequestSchema,
  GetLrpCoveragesReportResponse,
  getLrpCoveragesReportResponseSchema,
  GetLgmCoveragesReportResponse,
  getLgmCoveragesReportResponseSchema,
  GetAipIdentifiersRequest,
  AipIdentifier,
  getAipIdentifiersRequestSchema,
  aipIdentifiersResponseSchema,
  NewAipIdentifier,
  newAipIdentifierSchema,
  submitAipCoverageReqSchema,
  SubmitAipCoverageReq,
} from '@harvestiq/iiq/common';
import { orgIdHeader } from '@harvestiq/constants';
import { Logger, createLogger } from '@harvestiq/logger';
import { ApiResponse } from '@harvestiq/api';

const apiV1 = '/api/v1';
const rmaLrp = '/rma/lrp';
const rmaLgm = '/rma/lgm';

export enum INSUREIQ_ENDPOINTS {
  getToken = `${apiV1}/auth/token`,
  coverageReminder = `${apiV1}/coverages/notifications/reminder`,
  coverageIndemnity = `${apiV1}/coverages/notifications/indemnity`,
  updateExpiredCoverages = `${apiV1}/coverages/update-expired-coverages`,
  getAllOrgs = `${apiV1}/org-app-settings`,
  getOrgFmhKeys = `${apiV1}/org-app-settings/fmh-keys`,
  lrpOptions = `${apiV1}${rmaLrp}/options`,
  lrpQuotes = `${apiV1}${rmaLrp}/quotes`,
  lrpSalesEffectiveDates = `${apiV1}${rmaLrp}/sales-effective-dates`,
  lrpEndingValues = `${apiV1}${rmaLrp}/ending-values`,
  lrpIndemnity = `${apiV1}${rmaLrp}/indemnity`,
  lrpQuotesByTypeCodeAndSed = `${apiV1}${rmaLrp}/quotes-by-type-code-and-sed`,
  lgmPrices = `${apiV1}${rmaLgm}/prices`,
  lgmQuotes = `${apiV1}${rmaLgm}/quote`,
  lgmIndemnity = `${apiV1}${rmaLgm}/indemnity`,
  lgmSalesEffectiveDates = `${apiV1}${rmaLgm}/sales-effective-dates`,
  policies = `${apiV1}/policies`,
  policyApply = `${apiV1}/policies/apply`,
  coverages = `${apiV1}/coverages`,
  users = `${apiV1}/users`,
  aipIdentifiers = `${apiV1}/aip-events/aip-identifiers`,
  getCampaignQuotes = `${apiV1}/quoter/campaign`,
  getEntities = `${apiV1}/entities/search`,
  emailCoveragesReport = `${apiV1}/coverages/reports/email`,
  submitCoverageToAip = `${apiV1}/coverages/aip-submit`,
  getLrpCoveragesReport = `${apiV1}/coverages/reports/lrp`,
  getLgmCoveragesReport = `${apiV1}/coverages/reports/lgm`,
}

type InsureIQApiClientOptions = {
  baseUrl?: string;
  orgId?: string;
  clientId?: string;
  clientSecret?: string;
  logger?: Logger;
  authMethod?: 'jwt' | 'cookie';
};

/**
 * InsureIQ API Client
 * @param {InsureIQApiClientOptions} options - Options for the InsureIQ API Client
 * @param {string} options.baseUrl - Base URL for the InsureIQ API, e.g. http://localhost:3001
 * @param {string} options.orgId - Optional Org ID that service account can use to impersonate an org,
 * if not provided, the client will assume org based on baseUrl
 * @param {string} options.clientId - Client ID for the InsureIQ API
 * @param {string} options.clientSecret - Client Secret for the InsureIQ API
 * @param {Logger} options.logger - Logger or console instance
 */
export class InsureIQApiClient {
  _baseUrl: string;
  _orgId: string | undefined;
  _clientId: string;
  _clientSecret: string;
  _logger: Logger;
  _client: AxiosInstance;
  _token: string | null = null;
  _authMethod: 'jwt' | 'cookie';
  static loggingSensitiveUrls = [
    INSUREIQ_ENDPOINTS.getToken,
    INSUREIQ_ENDPOINTS.getOrgFmhKeys,
  ];

  constructor({
    baseUrl,
    clientId,
    clientSecret,
    orgId,
    logger,
    authMethod,
  }: InsureIQApiClientOptions) {
    this._baseUrl = baseUrl || getSGPortalHost();
    this._orgId = orgId;
    this._clientId = clientId || getSGPortalClientId();
    this._clientSecret = clientSecret || getSGPortalClientSecret();
    this._logger = logger || createLogger('InsureIQApiClient');
    this._authMethod = authMethod || 'jwt';

    this._client = axios.create({
      baseURL: this._baseUrl,
      timeout: 60 * 1000, // 1 minute
      withCredentials: authMethod === 'cookie',
    });

    this._client.interceptors.request.use(this._logRequestInterceptor);
    this._client.interceptors.response.use(this._logResponseInterceptor);
  }

  _sanitizeLogPayload = (url: string | undefined, payload: unknown) => {
    if (url) {
      return InsureIQApiClient.loggingSensitiveUrls.includes(
        url as INSUREIQ_ENDPOINTS
      )
        ? 'Sensitive payload not displayed in logs'
        : payload;
    }
    return payload;
  };

  _logRequestInterceptor = (config: InternalAxiosRequestConfig) => {
    try {
      const payload = config.data ? config.data : config.params;
      const sanitizedLogPayload = this._sanitizeLogPayload(config.url, payload);
      const message = `[InsureIQApiClient] Request: ${config.method?.toUpperCase()} ${
        config.url
      }`;

      if (sanitizedLogPayload) {
        this._logger.debug(message, sanitizedLogPayload);
      } else {
        this._logger.debug(message);
      }
    } catch (error) {
      this._logger.error('[InsureIQApiClient] Error logging request', error);
    }
    return config;
  };

  _logResponseInterceptor = (response: AxiosResponse) => {
    try {
      const sanitizedLogPayload = this._sanitizeLogPayload(
        response.config.url,
        response.data
      );
      const message = `[InsureIQApiClient] Response: ${
        response.status
      } ${response.config.method?.toUpperCase()} ${response.config.url}:`;

      if (sanitizedLogPayload) {
        this._logger.debug(`${message}:`, sanitizedLogPayload);
      } else {
        this._logger.debug(message);
      }
    } catch (error) {
      this._logger.error('[InsureIQApiClient] Error logging response', error);
    }
    return response;
  };

  private async getToken() {
    this._logger.debug('[InsureIQApiClient] Requesting Token from InsureIQ');
    const resp = await this._client.post(INSUREIQ_ENDPOINTS.getToken, {
      clientId: this._clientId,
      clientSecret: this._clientSecret,
    });
    this._token = resp.data.accessToken;
    if (this._token) {
      this._logger.debug('[InsureIQApiClient] Token received');
    } else {
      this._logger.error(
        '[InsureIQApiClient] Unable to get token for InsureIQ'
      );
    }
  }

  setOrgId(orgId: string) {
    this._orgId = orgId;
    return this;
  }

  // Clear org specific context from the client instance
  reset() {
    this._orgId = undefined;
    this._token = null;
    return this;
  }

  protected async getHeaders() {
    const headers: Record<string, string> = {};
    if (this._authMethod !== 'cookie') {
      if (!this._token) {
        await this.getToken();
      }
      headers['authorization'] = `Bearer ${this._token}`;

      // Allow service account to impersonate org
      if (this._orgId) {
        headers[orgIdHeader] = this._orgId;
      }
    }
    return headers;
  }

  protected logIfBadRequest(
    err: AxiosError,
    additionalInfo: {
      endpoint?: string | INSUREIQ_ENDPOINTS;
    } = {}
  ) {
    if (err.response?.status === 400) {
      const method = err.request?.method || 'Unknown Method';
      const url = err.request?.url || additionalInfo?.endpoint || 'Unknown URL';

      this._logger.error(
        `[InsureIQApiClient] BAD REQUEST: ${method} to ${url}`,
        {
          error: err.response?.data,
          fullError: err,
        }
      );
    }
  }

  protected async makeRequest<
    ResponseT,
    RequestParamsT = void,
    RequestBodyT = void
  >({
    method,
    endpoint,
    responseSchema,
    requestParams,
    requestBody,
    requestParamsSchema,
    requestBodySchema,
    keepResponseDataOnError,
    retries = 2,
  }: {
    method: 'get' | 'post' | 'patch' | 'delete';
    endpoint: string;
    responseSchema: z.ZodTypeAny;
    requestParams?: RequestParamsT;
    requestBody?: RequestBodyT;
    requestParamsSchema?: z.ZodTypeAny;
    requestBodySchema?: z.ZodTypeAny;
    retries?: number;
    keepResponseDataOnError?: boolean;
  }): Promise<ResponseT | null> {
    // Verify the request params and body are correctly formatted
    const parsedParams = requestParamsSchema
      ? requestParamsSchema.parse(requestParams)
      : requestParams;
    const parsedBody = requestBodySchema
      ? requestBodySchema.parse(requestBody)
      : requestBody;

    let resp;
    try {
      const headers = await this.getHeaders();
      resp = await this._client.request({
        method,
        url: endpoint,
        headers,
        ...(parsedParams && { params: parsedParams }),
        ...(parsedBody && { data: parsedBody }),
      });

      if (resp.status === 0) {
        throw new Error(
          `Network Error for ${method.toUpperCase()} ${endpoint}`
        );
      }
    } catch (err) {
      if (!axios.isAxiosError(err)) {
        throw err;
      }

      if (err.response?.status === 401 && retries > 0) {
        this._logger.warn(
          `[InsureIQApiClient] Received 401. Retrying ${method.toUpperCase()} ${endpoint} ${retries} more time(s).`
        );
        // clear bad token and re-call
        this._token = null;
        return await this.makeRequest({
          method,
          endpoint,
          responseSchema,
          requestParams,
          requestBody,
          requestParamsSchema,
          requestBodySchema,
          retries: retries - 1,
        });
      } else if (err.response?.status === 401) {
        this._logger.error(
          `[InsureIQApiClient] Retries exhausted for ${method.toUpperCase()} ${endpoint} request. Request consistently receiving 401 errors. Check that API token has correct scope for the endpoint.`
        );
      }

      this.logIfBadRequest(err, {
        endpoint,
      });

      if (keepResponseDataOnError) {
        return err.response?.data;
      }

      return null;
    }
    const parsedData = responseSchema.parse(resp.data);
    return parsedData;
  }

  /**
   * @deprecated use makeRequest instead
   */
  private async callGetApi<ResponseT, RequestParamsT = void>(
    endpoint: INSUREIQ_ENDPOINTS | string,
    responseSchema: z.ZodTypeAny,
    request?: RequestParamsT,
    requestSchema?: z.ZodTypeAny,
    retries = 2
  ): Promise<ResponseT> {
    const parsedParams = requestSchema ? requestSchema.parse(request) : request;
    let resp;
    try {
      const headers = await this.getHeaders();
      resp = await this._client.get(endpoint, {
        headers,
        params: parsedParams,
      });
    } catch (err) {
      if (!axios.isAxiosError(err)) {
        throw err;
      }

      if (err.response?.status == 401 && retries > 0) {
        this._logger.warn(
          `[InsureIQApiClient] Received 401. Retrying GET ${endpoint} ${retries} more time(s).`
        );
        // clear bad token and re-call
        this._token = null;
        const remainingRetries = retries - 1;
        return await this.callGetApi(
          endpoint,
          responseSchema,
          request,
          requestSchema,
          remainingRetries
        );
      } else if (err.response?.status === 401) {
        this._logger.error(
          `[InsureIQApiClient] Retries exhausted for GET ${endpoint} request. Request consistently receiving 401 errors. Check that API token has correct scope for the endpoint.`
        );
      }

      this.logIfBadRequest(err, {
        endpoint: endpoint,
      });

      throw err;
    }
    const parsedData = responseSchema.parse(resp.data);
    return parsedData;
  }

  /**
   * @deprecated use makeRequest instead
   */
  private async callPostApi<ResponseT, RequestT>(
    endpoint: INSUREIQ_ENDPOINTS,
    responseSchema: z.ZodTypeAny,
    request: RequestT,
    requestSchema: z.ZodTypeAny,
    retries = 2
  ): Promise<ResponseT> {
    requestSchema.parse(request);

    let resp;
    try {
      const headers = await this.getHeaders();
      resp = await this._client.post(endpoint, request, { headers });
    } catch (err) {
      if (!axios.isAxiosError(err)) {
        this._logger.error(
          `[InsureIQApiClient] Error for POST ${endpoint} request is NOT an Axios error.`,
          err
        );
        throw err;
      }

      if (err.response?.status == 401 && retries > 0) {
        this._logger.warn(
          `[InsureIQApiClient] Received 401. Retrying POST ${endpoint} ${retries} more time(s).`
        );
        // clear bad token and re-call
        this._token = null;
        const remainingRetries = retries - 1;
        return await this.callPostApi(
          endpoint,
          responseSchema,
          request,
          requestSchema,
          remainingRetries
        );
      } else if (err.response?.status === 401) {
        this._logger.error(
          `[InsureIQApiClient] Retries exhausted for POST ${endpoint} request. Request consistently receiving 401 errors. Check that API token has correct scope for the endpoint.`
        );
      }

      this.logIfBadRequest(err, {
        endpoint: endpoint,
      });

      this._logger.error(
        `[InsureIQApiClient] Error for POST ${endpoint} request not matched any known error.`,
        err
      );

      throw err;
    }
    const parsedData = responseSchema.parse(resp.data);
    return parsedData;
  }

  async getLrpOptions() {
    return await this.callGetApi<LrpOptionsResponse>(
      INSUREIQ_ENDPOINTS.lrpOptions,
      lrpOptionsResponseSchema
    );
  }

  async getLrpQuotes(request: LrpQuoteRequest) {
    return await this.callGetApi<LrpQuotesResponse, LrpQuoteRequest>(
      INSUREIQ_ENDPOINTS.lrpQuotes,
      lrpQuotesResponseSchema,
      request,
      lrpQuoteRequestSchema
    );
  }

  async getCoverage(coverageId: string) {
    const response = await this.makeRequest<GetCoverageResponse>({
      method: 'get',
      endpoint: `${INSUREIQ_ENDPOINTS.coverages}/${coverageId}`,
      responseSchema: getCoverageResponseSchema,
    });

    return response?.coverage ?? null;
  }

  async getCoverages(query: GetCoveragesRequest) {
    const response = await this.makeRequest<
      GetCoveragesResponse,
      GetCoveragesRequest
    >({
      method: 'get',
      endpoint: INSUREIQ_ENDPOINTS.coverages,
      responseSchema: getCoveragesResponseSchema,
      requestParams: query,
      requestParamsSchema: getCoveragesRequestSchema,
    });

    return response;
  }

  async getLrpSalesEffectiveDates() {
    return await this.callGetApi<SalesEffectiveDatesResponse>(
      INSUREIQ_ENDPOINTS.lrpSalesEffectiveDates,
      salesEffectiveDatesResponseSchema
    );
  }

  async getLrpQuotesByTypeCodeAndSed(request: LrpQuoteByTypeCodeAndSedRequest) {
    return await this.callGetApi<
      LrpQuoteByTypeCodeAndSedResponse,
      LrpQuoteByTypeCodeAndSedRequest
    >(
      INSUREIQ_ENDPOINTS.lrpQuotesByTypeCodeAndSed,
      lrpQuoteByTypeCodeAndSedResponseSchema,
      request,
      lrpQuoteByTypeCodeAndSedRequestSchema
    ).catch((error) => {
      this._logger.error('[getLrpQuotesByTypeCodeAndSed] Error:', error);
      return null;
    });
  }

  async getLrpEndingValues(request: LrpEndingValuesRequest) {
    const response = await this.callGetApi<
      LrpEndingValuesResponse,
      LrpEndingValuesRequest
    >(
      INSUREIQ_ENDPOINTS.lrpEndingValues,
      lrpEndingValuesResponseSchema,
      request,
      lrpEndingValuesRequestSchema
    ).catch((error) => {
      this._logger.error('[getLrpEndingValues] Error:', error);
      return null; // In case of error we must display the coverages without the calculated value, not throw an error that blocks the user
    });
    return response?.data ?? [];
  }

  async getLrpIndemnity(request: LrpIndemnityRequest) {
    const response = await this.callGetApi<
      LrpIndemnityResponse,
      LrpIndemnityRequest
    >(
      INSUREIQ_ENDPOINTS.lrpIndemnity,
      lrpIndemnityResponseSchema,
      request,
      lrpIndemnityRequestSchema
    ).catch((error) => {
      this._logger.error('[getLrpIndemnity] Error:', error);
      return null; // In case of error we must display the coverages without the calculated value, not throw an error that blocks the user
    });

    return response?.data ?? [];
  }

  async getLrpCoveragesReport(query: GetCoveragesRequest) {
    const response = await this.makeRequest<
      GetLrpCoveragesReportResponse,
      GetCoveragesRequest
    >({
      method: 'get',
      endpoint: INSUREIQ_ENDPOINTS.getLrpCoveragesReport,
      responseSchema: getLrpCoveragesReportResponseSchema,
      requestParams: query,
      requestParamsSchema: getCoveragesRequestSchema,
    });

    return response;
  }

  async getLgmPrices(request: LgmPricesRequest) {
    const response = await this.callGetApi<LgmPricesResponse, LgmPricesRequest>(
      INSUREIQ_ENDPOINTS.lgmPrices,
      lgmPricesResponseSchema,
      request,
      lgmPricesRequestSchema
    ).catch((error) => {
      this._logger.error('[getLgmPrices] Error:', error);
      return null; // In case of error we must display the coverages without the calculated value, not throw an error that blocks the user
    });

    return response?.data ?? [];
  }

  async getLgmQuotes(request: LgmQuoteRequest) {
    return await this.callGetApi<GetLgmQuoteResponse, LgmQuoteRequest>(
      INSUREIQ_ENDPOINTS.lgmQuotes,
      getLgmQuoteResponseSchema,
      request,
      lgmQuoteRequestSchema
    );
  }

  async getLgmIndemnity(request: LgmIndemnityRequest) {
    const response = await this.callGetApi<
      LgmIndemnityResponse,
      LgmIndemnityRequest
    >(
      INSUREIQ_ENDPOINTS.lgmIndemnity,
      lgmIndemnityResponseSchema,
      request,
      lgmIndemnityRequestSchema
    ).catch((error) => {
      this._logger.error('[getLgmIndemnity] Error:', error);
      return null; // In case of error we must display the coverages without the calculated value, not throw an error that blocks the user
    });

    return response?.data ?? [];
  }

  async getLgmSalesEffectiveDates() {
    return await this.callGetApi<SalesEffectiveDatesResponse>(
      INSUREIQ_ENDPOINTS.lgmSalesEffectiveDates,
      salesEffectiveDatesResponseSchema
    );
  }

  async getLgmCoveragesReport(query: GetCoveragesRequest) {
    const response = await this.makeRequest<
      GetLgmCoveragesReportResponse,
      GetCoveragesRequest
    >({
      method: 'get',
      endpoint: INSUREIQ_ENDPOINTS.getLgmCoveragesReport,
      responseSchema: getLgmCoveragesReportResponseSchema,
      requestParams: query,
      requestParamsSchema: getCoveragesRequestSchema,
    });

    return response;
  }

  async createUser(request: CreateUserRequest) {
    const response = await this.callPostApi<
      CreateUserResponse,
      CreateUserRequest
    >(
      INSUREIQ_ENDPOINTS.users,
      createUserResponseSchema,
      request,
      createUserRequestSchema
    );

    return response.data.user;
  }

  async getUser({ email }: { email: string }) {
    const params = {
      search: email,
    };
    const response = await this.makeRequest<GetUserResponse, GetUserRequest>({
      method: 'get',
      endpoint: INSUREIQ_ENDPOINTS.users,
      requestParams: params,
      requestParamsSchema: getUserRequestSchema,
      responseSchema: getUserResponseSchema,
    });
    if (response?.data?.users?.length && response?.data?.users?.length > 0) {
      // Only existing endpoint for getting a user by email is a search not a single user
      // so we need to validate the user on exact email match
      const user = response.data.users.find((u) => u && u.email === email);
      return user || null;
    }
    return null;
  }

  async getUserById({ userId }: { userId: string }) {
    const response = await this.makeRequest<GetUserByIdResponse>({
      method: 'get',
      endpoint: `${INSUREIQ_ENDPOINTS.users}/${userId}`,
      responseSchema: getUserByIdResponseSchema,
    });
    if (response && response.user) {
      return response.user;
    }
    return null;
  }

  async updateUser({ userId }: { userId: string }, body: UpdateUserRequest) {
    const response = await this.makeRequest<
      UpdateUserResponse,
      void,
      UpdateUserRequest
    >({
      method: 'patch',
      endpoint: `${INSUREIQ_ENDPOINTS.users}/${userId}`,
      responseSchema: updateUserResponseSchema,
      requestBody: body,
      requestBodySchema: updateUserRequestSchema,
    });

    if (response) {
      return response.user;
    }
    return null;
  }

  async updateUserProfile(
    { userId }: { userId: string },
    body: UpdateUserProfileRequest
  ) {
    const response = await this.makeRequest<
      UpdateUserProfileResponse,
      void,
      UpdateUserProfileRequest
    >({
      method: 'patch',
      endpoint: `${INSUREIQ_ENDPOINTS.users}/${userId}/profile`,
      responseSchema: updateUserProfileResponseSchema,
      requestBody: body,
      requestBodySchema: updateUserProfileRequestSchema,
    });
    return response;
  }

  async updateUserAgent(
    { userId }: { userId: string },
    body: UpdateUserAgentRequest
  ) {
    const response = await this.makeRequest<
      UpdateUserAgentResponse,
      void,
      UpdateUserAgentRequest
    >({
      method: 'patch',
      endpoint: `${INSUREIQ_ENDPOINTS.users}/${userId}/agents`,
      responseSchema: updateUserAgentResponseSchema,
      requestBody: body,
      requestBodySchema: updateUserAgentRequestSchema,
    });
    return response;
  }

  async getPolicy({ policyId }: { policyId: string }) {
    const response = await this.makeRequest<ApiResponse<DbPolicy>>({
      method: 'get',
      endpoint: `${INSUREIQ_ENDPOINTS.policies}/${policyId}`,
      responseSchema: getPolicyResponseSchema,
    });
    return response?.data[0] ?? null;
  }

  async createPolicy(request: CreatePolicyRequest) {
    const response = await this.callPostApi<
      CreatePolicyResponse,
      CreatePolicyRequest
    >(
      INSUREIQ_ENDPOINTS.policyApply,
      createPolicyResponseSchema,
      request,
      createPolicyRequestSchema
    );
    return response;
  }

  async updatePolicy({ policyId }: { policyId: string }, body: DbPolicyUpdate) {
    const response = await this.makeRequest<
      UpdatePolicyResponse,
      void,
      DbPolicyUpdate
    >({
      method: 'patch',
      endpoint: `${INSUREIQ_ENDPOINTS.policies}/${policyId}`,
      responseSchema: updatePolicyResponseSchema,
      requestBody: body,
      requestBodySchema: dbPolicyUpdateSchema,
    });

    if (response) {
      return response.policy;
    }
    return null;
  }

  async getCoverageReminders(request: CoverageReminderApiRequest) {
    return await this.callGetApi<
      CoverageReminderApiResponse,
      CoverageReminderApiRequest
    >(
      INSUREIQ_ENDPOINTS.coverageReminder,
      coverageReminderApiResponseSchema,
      request,
      coverageReminderApiRequestSchema
    );
  }

  async getCoverageIndemnities(request: CoverageIndemnityApiRequest) {
    return await this.callGetApi<
      CoverageIndemnityApiResponse,
      CoverageIndemnityApiRequest
    >(
      INSUREIQ_ENDPOINTS.coverageIndemnity,
      coverageIndemnityApiResponseSchema,
      request,
      coverageIndemnityApiRequestSchema
    );
  }

  async updateExpiredCoverages() {
    return await this.callPostApi<string, null>(
      INSUREIQ_ENDPOINTS.updateExpiredCoverages,
      z.literal('OK'), // sendStatus(200)
      null,
      z.null()
    );
  }

  async createLrpCoverage(request: CreateLrpCoverageRequest) {
    const response = await this.callPostApi<
      CreateLrpCoverageResponse,
      CreateLrpCoverageRequest
    >(
      INSUREIQ_ENDPOINTS.coverages,
      createLrpCoverageResponseSchema,
      request,
      createLrpCoverageRequestSchema
    );
    return response;
  }

  async updateLrpCoverage(coverageId: string, body: UpdateLrpCoverageRequest) {
    const response = await this.makeRequest<
      UpdateLrpCoverageResponse,
      void,
      UpdateLrpCoverageRequest
    >({
      method: 'patch',
      endpoint: `${INSUREIQ_ENDPOINTS.coverages}/${coverageId}`,
      responseSchema: updateLrpCoverageResponseSchema,
      requestBody: body,
      requestBodySchema: updateLrpCoverageRequestSchema,
    });
    return response?.data;
  }

  async createLgmCoverage(request: CreateLgmCoverageRequest) {
    const response = await this.callPostApi<
      CreateLgmCoverageResponse,
      CreateLgmCoverageRequest
    >(
      INSUREIQ_ENDPOINTS.coverages,
      createLgmCoverageResponseSchema,
      request,
      createLgmCoverageRequestSchema
    );
    return response;
  }

  async updateLgmCoverage(coverageId: string, body: UpdateLgmCoverageRequest) {
    const response = await this.makeRequest<
      UpdateLgmCoverageResponse,
      void,
      UpdateLgmCoverageRequest
    >({
      method: 'patch',
      endpoint: `${INSUREIQ_ENDPOINTS.coverages}/${coverageId}`,
      responseSchema: updateLgmCoverageResponseSchema,
      requestBody: body,
      requestBodySchema: updateLgmCoverageRequestSchema,
    });
    return response?.data;
  }

  async emailCoveragesReport(body: InsureIQEmailCoveragesReportRequest) {
    const response = await this.makeRequest<
      InsureIQEmailCoveragesReportResponse,
      void,
      InsureIQEmailCoveragesReportRequest
    >({
      method: 'post',
      endpoint: INSUREIQ_ENDPOINTS.emailCoveragesReport,
      responseSchema: insureIQEmailCoveragesReportResponseSchema,
      requestBody: body,
      requestBodySchema: insureIQEmailCoveragesReportRequestSchema,
    });
    return response;
  }

  async deleteCoverage(coverageId: string) {
    return await this.makeRequest<string | Pick<ApiResponse, 'errors'>, null>({
      method: 'delete',
      endpoint: `${INSUREIQ_ENDPOINTS.coverages}/${coverageId}`,
      responseSchema: z.literal('OK'),
      keepResponseDataOnError: true,
    });
  }

  async getAllOrgs() {
    return await this.callGetApi<AllOrgAppSettingsResponse>(
      INSUREIQ_ENDPOINTS.getAllOrgs,
      allOrgAppSettingsResponseSchema
    );
  }

  async getOrgById({ orgId }: { orgId: string }) {
    const allOrgsResponse = await this.getAllOrgs();
    const foundOrg = allOrgsResponse.data.find((org) => org.id === orgId);
    return foundOrg ?? null;
  }

  async getOrgFmhKeys() {
    const response = await this.makeRequest<GetOrgFmhKeysResponse>({
      method: 'get',
      endpoint: INSUREIQ_ENDPOINTS.getOrgFmhKeys,
      responseSchema: getOrgFmhKeysResponseSchema,
    });
    return response?.data[0] ?? null;
  }

  async getAipIdentifiers(request: GetAipIdentifiersRequest) {
    const response = await this.callGetApi<
      ApiResponse<AipIdentifier>,
      GetAipIdentifiersRequest
    >(
      INSUREIQ_ENDPOINTS.aipIdentifiers,
      aipIdentifiersResponseSchema,
      request,
      getAipIdentifiersRequestSchema
    );

    return response.data;
  }

  async createAipIdentifier(request: NewAipIdentifier) {
    const response = await this.callPostApi<
      ApiResponse<AipIdentifier>,
      NewAipIdentifier
    >(
      INSUREIQ_ENDPOINTS.aipIdentifiers,
      aipIdentifiersResponseSchema,
      request,
      newAipIdentifierSchema
    );

    return response.data;
  }

  async submitCoverageToAip(body: SubmitAipCoverageReq) {
    return await this.makeRequest<
      Pick<ApiResponse, 'errors'>,
      void,
      SubmitAipCoverageReq
    >({
      method: 'post',
      endpoint: `${INSUREIQ_ENDPOINTS.submitCoverageToAip}`,
      requestBody: body,
      requestBodySchema: submitAipCoverageReqSchema,
      // @todo remove any once we have a response schema
      responseSchema: z.any(),
      keepResponseDataOnError: true,
    });
  }

  async getQuoteEmailData(request: InsureIQQuoteCampaignRequest) {
    const quoteData = await this.callPostApi<
      InsureIQInsuranceQuoteResponse,
      InsureIQQuoteCampaignRequest
    >(
      INSUREIQ_ENDPOINTS.getCampaignQuotes,
      insureIQInsuranceQuoteResponseSchema,
      request,
      insureIQQuoteCampaignRequestSchema
    );

    return quoteData;
  }

  async getEntities(params?: SearchEntitiesRequest) {
    const entitiesRequestParams = params
      ? {
          requestParams: params,
          requestParamsSchema: searchEntitiesRequestSchema,
        }
      : {};

    const response = await this.makeRequest<
      SearchEntitiesResponse,
      SearchEntitiesRequest
    >({
      method: 'get',
      endpoint: INSUREIQ_ENDPOINTS.getEntities,
      responseSchema: searchEntitiesResponseSchema,
      ...entitiesRequestParams,
    });

    return response;
  }
}
